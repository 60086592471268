//------------------------------------
//	# Mask Controller
//------------------------------------

import { Controller } from "stimulus"
import Inputmask from "inputmask";


export default class extends Controller {
  static targets = [
    'inputCvv',
    'inputCardNumber',
    'inputCardExpMonth',
    'inputCardExpYear'
  ];

  initialize() {
    Inputmask({ 'mask': '9999 9999 9999 9999' }).mask(this.inputCardNumberTarget);
    Inputmask({ 'mask': '99', alias: 'datemonth', inputFormat: 'mm' }).mask(this.inputCardExpMonthTarget);
    Inputmask({ 'mask': '99', alias: 'dateyear', inputFormat: 'yy' }).mask(this.inputCardExpYearTarget);
    Inputmask({ 'mask': '999' }).mask(this.inputCvvTarget);
  }
}