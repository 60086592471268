//------------------------------------
//	# Dropdown Controller
//------------------------------------

import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ['dropdownMenu'];

  toggle() {
    this.dropdownMenuTarget.classList.toggle('show');
  }

  close(event) {
    event.preventDefault();
    this.dropdownMenuTarget.classList.remove('show');
  }

}