//------------------------------------
//	# Mask Controller
//------------------------------------

import { Controller } from "stimulus"
import Inputmask from "inputmask";


export default class extends Controller {
    static targets = [
        'inputPhoneNumber'
  ];

    initialize() {
        Inputmask({ 'mask': '(999) 999 99 99' }).mask(this.inputPhoneNumberTarget);
    }
}