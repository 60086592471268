//------------------------------------
//	# Upload Controller
//------------------------------------

import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ['submit'];

  upload() {
    this.submitTarget.click();
  }

}