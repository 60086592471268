//------------------------------------
//	# Payment Validation Controller
//------------------------------------

import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ['ccOwner', 'cardNumber', 'expiryMonth',
    'expiryYear', 'cvv', 'submitButton'];

  connect() {
    this.validate()
  }

  validate() {
    if(this.validateAll()) {
      this.submitButtonTarget.removeAttribute('disabled')
    } else {
      this.submitButtonTarget.setAttribute('disabled', true)
    }
  }

  validateAll() {
    return this.validateCCOwner() && this.validateCardNumber() &&
      this.validateExpiryMonth() && this.validateExpiryYear() && this.validateCVV()
  }

  validateCCOwner() {
    let value = this.ccOwnerTarget.value
    return value.trim()
  }

  validateCardNumber() {
    let value = this.cardNumberTarget.inputmask.unmaskedvalue()
    return value.trim() && value.length === 16
  }

  validateExpiryMonth() {
    let value = this.expiryMonthTarget.inputmask.unmaskedvalue()
    let intValue = parseInt(value, 10)
    return value.trim() && value.length === 2 && intValue > 0 && intValue < 13
  }

  validateExpiryYear() {
    let value = this.expiryYearTarget.inputmask.unmaskedvalue()
    return value.trim() && value.length === 2 && !isNaN(value)
  }

  validateCVV() {
    let value = this.cvvTarget.value
    return value.trim() && value.length === 3 && !isNaN(value)
  }
}
