//------------------------------------
//	# Orders Controller
//------------------------------------

import { Controller } from "stimulus"
import { ajax } from '@rails/ujs'

export default class extends Controller {

    static targets = ['cartItemList', 'cartItemForm', 'cartItemId', 'orderAmountInput',
        'stockCodeInput', 'quantityInput', 'priceInput', 'discountedPriceInput', 'parentId'];


    addCartItem(event){
        event.preventDefault();
        let stockCode = this.stockCodeInputTarget.value;
        let quantityInput = this.quantityInputTarget.value;
        let parentId = event.target.dataset.parentId;

        ajax({
            url: `${event.target.dataset.cartItemPath}`,
            data: 'stock_code=' + `${stockCode}` + '&quantity=' + `${quantityInput}` + '&parent_id=' + `${parentId}`,
            type: 'POST',
            accept: 'html',
            success: (response, status, xhr) => {
                if(status === 'Accepted'){
                    this.cartItemListTarget.innerHTML = xhr.response;
                    this.totalAmount(this.cartItemListTarget);
                    this.clearFields();
                    this.clearErrors();
                }
            },
            error: (response, statusText, xhr) => {
                this.cartItemFormTarget.innerHTML = xhr.response;
            }
        });
    }

    updateCartItem(){
        event.preventDefault();
        let parentId = event.target.dataset.parentId;
        let cartItemId = event.target.dataset.cartItemId;
        let cartItem = document.getElementById('cart_item_' + `${cartItemId}`)
        let inputs = cartItem.getElementsByTagName('input')
        let quantityInput = inputs[1].value;

        ajax({
            url: `${event.target.dataset.cartItemPath}`,
            data: 'quantity=' + `${quantityInput}` + '&parent_id=' + `${parentId}`,
            type: 'PATCH',
            accept: 'html',
            success: (response, status, xhr) => {
                cartItem.outerHTML = xhr.response;
                // Blinking
                cartItem = document.getElementById('cart_item_' + `${cartItemId}`)
                inputs = cartItem.getElementsByTagName('input')
                inputs[1].classList.add('blinking')
                this.totalAmount(this.cartItemListTarget);
            },
            error: (response, statusText, xhr) => {
                cartItem.outerHTML = xhr.response;
            }
        });
    }

    removeCartItem(event){
        event.preventDefault();
        ajax({
            url: `${event.target.dataset.cartItemPath}`,
            type: 'DELETE',
            accept: 'html',
            success: (response, status, xhr) => {
                let cartItemId = event.target.dataset.cartItemId;
                let cartItem = document.getElementById('cart_item_' + `${cartItemId}`)
                cartItem.remove();
                this.totalAmount(this.cartItemListTarget);
            },
            error: (response, statusText, xhr) => {
                console.error(response, statusText)
            }
        });
    }

    fetchPriceAndDiscountedPriceInfo(event){
        let stockCode = this.stockCodeInputTarget.value;
        let quantityInput = this.quantityInputTarget.value;
        let priceInputTarget = this.priceInputTarget;
        let discountedPriceInputTarget = this.discountedPriceInputTarget;

        if (stockCode.length === 8 && quantityInput !== "") {
            ajax({
                url: `${event.target.dataset.cartItemPath}`,
                data: 'stock_code=' + `${stockCode}` + '&quantity=' + `${quantityInput}`,
                type: 'GET',
                accept: 'html',
                success: function(response) {
                    priceInputTarget.value = response['price'] + ' ₺';
                    discountedPriceInputTarget.value = (response['discounted_price'] === '- Boş -') ? response['discounted_price'] : response['discounted_price'] + ' ₺';
                }
            });
        }
    }

    totalAmount(list){
        let inputs = list.getElementsByTagName('input');
        let length = (inputs.length + 1) / 4;
        let totalAmount = 0;
        let price;
        let discountedPrice;

        for(var i = 0; i < length -1; i++){
            price = parseInt(inputs[2 + (4 * i)].value.trim().split(" & ")[0]);
            discountedPrice = parseInt(inputs[3 + (4 * i)].value.trim().split(" & ")[0]);

            if(discountedPrice){
                totalAmount = totalAmount + discountedPrice;
            }else{
                totalAmount = totalAmount + price;
            }
        }
        this.orderAmountInputTarget.value = totalAmount + ',00 ₺';
    }

    clearFields(){
        this.stockCodeInputTargets[0].value = '';
        this.quantityInputTargets[0].value = '';
        this.priceInputTargets[0].value = '';
        this.discountedPriceInputTargets[0].value = '';
    }

    clearErrors(){
        this.stockCodeInputTargets[0].classList.remove('is-invalid');
        this.quantityInputTargets[0].classList.remove('is-invalid');
        document.querySelectorAll('.invalid-feedback').forEach((element) => {
            element.remove();
        });
    }
}