//------------------------------------
//	# Image preview Controller
//------------------------------------

import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ['image', 'button', 'defaultUploadSvg'];

  upload(event) {
    if(this.hasImageTarget){
      // For update
      if(!this.hasDefaultUploadSvgTarget){
        this.imageTargets[0].insertAdjacentHTML("beforebegin", `
        <div class="col-md-3 mb-1" data-target="store-manager--preview.defaultUploadSvg">
           <img src="" alt="Preview image" class="img-thumbnail" width="400" heigth="auto">
        </div> `)
      }

      this.imageTargets.forEach(image => image.remove());
    }

    for(var i = 0; i < event.currentTarget.files.length; i++){
      this.defaultUploadSvgTarget.insertAdjacentHTML("beforebegin", `
        <div class="col-md-3 mb-1" data-target="store-manager--preview.image">
            <img src=${window.URL.createObjectURL(event.currentTarget.files[i])} alt="Preview image" class="img-thumbnail"  width="400" heigth="auto">
        </div> `)
    }
    this.buttonTarget.classList.add('btn-image-change');
    this.defaultUploadSvgTarget.hidden = true;
  }
}