//------------------------------------
//	# Payment Controller
//------------------------------------

import { Controller } from "stimulus"
import { ajax } from '@rails/ujs'
import Inputmask from "inputmask";

export default class extends Controller {

  static targets = ['valuesContainer', 'installmentOptionsContainer', 'installmentOptionsInfo', 'inputCardNumber'];

  insertValuesAndSubmit(event) {
    event.preventDefault();

    let query = ''
    let installmentValue = this.installmentOptionsContainerTarget.value
    if (installmentValue !== '') {
      let cardType = this.installmentOptionsContainerTarget.options[0].dataset.cardType;
      query = `?installment_value=${installmentValue}&card_type=${cardType}`
    }
    ajax({
      url: this.data.get('path') + query,
      type: 'POST',
      success: (document, status, response) => {
        this.valuesContainerTarget.innerHTML = response.responseText;
        this.clearInputMask();
        this.element.submit();
      }
    });
  }

  fetchInstallmentInfo(event) {
    let binNumber = event.target.inputmask.unmaskedvalue();
    this.fetchOptionsFor(binNumber);
  }

  fetchOptionsFor(binNumber) {
    if (binNumber.length > 5) {
      if (this.fetchedBin === binNumber.slice(0, 6)) {
        return;
      } else {
        this.fetchedBin = binNumber.slice(0, 6);
      }
      ajax({
        url: `${this.data.get('installment-path')}?bin_number=${binNumber.slice(0, 6)}`,
        type: 'GET',
        accept: 'html',
        success: (response, status, xhr) => {
          this.fetchedBin = binNumber.slice(0, 6);
          this.installmentOptionsContainerTarget.innerHTML = xhr.response;
          this.installmentOptionsContainerTarget.removeAttribute('hidden');
          this.installmentOptionsInfoTarget.setAttribute('hidden', true);
        },
        error: (response, statusText, xhr) => {
          console.error(response, statusText)
        }
      });
    } else {
      this.fetchedBin = null;
      this.installmentOptionsInfoTarget.removeAttribute('hidden');
      this.installmentOptionsContainerTarget.setAttribute('hidden', true);
    }
  }

  clearInputMask() {
    Inputmask({ clearIncomplete: true }).mask(this.inputCardNumberTarget)
  }
}

