//------------------------------------
//	# Toast component
//------------------------------------

import { Controller } from 'stimulus';

export default class extends Controller {

  initialize() {
    this.element.classList.add('show');
    this.count = setInterval(() => {
      this.close();
    }, 6000);
  }

  close() {
    this.element.classList.remove('show');
  }

  disconnect() {
    clearInterval(this.count);
  }

}

