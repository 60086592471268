//------------------------------------
//	# Product Variant Controller
//------------------------------------

import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {

  changeForm(event) {
    Rails.ajax({
      url: event.target.dataset.productVariantOptionPath,
      type: 'GET',
      data: 'category_id=' + `${event.target.dataset.categoryId}` + '&id=' + `${event.target.dataset.id}` + '&selected_body_size=' + `${event.target.value}` + '&color=' + `${event.target.dataset.color}`,
      accept: 'html',
      success: (response, status, xhr) => {
        document.getElementById(event.target.dataset.color).outerHTML = xhr.response;
        document.getElementById('body_size_info_of_' + `${event.target.dataset.color}`).textContent = response.body.querySelector('select').dataset.bodySizeInformation;
      },
      error: (error) => {
        console.error(error);
      }
    });
  }
}