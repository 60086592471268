//------------------------------------
//	# Payments Controller
//------------------------------------

import { Controller } from "stimulus"
import {ajax} from "@rails/ujs";

export default class extends Controller {

    static targets = ['paymentForm', 'paymentList', 'paymentType', 'paymentAmount', 'note'];

    addPayment(event){
        let [data, status, xhr] = event.detail;
        if(status === 'Accepted'){
            this.paymentListTarget.innerHTML = xhr.response;
            this.clearFields();
            this.clearErrors();
        }else{
            this.paymentFormTarget.innerHTML = xhr.response;
        }
    }

    removePayment(event){
        event.preventDefault();
        ajax({
            url: `${event.target.dataset.paymentPath}`,
            type: 'DELETE',
            accept: 'html',
            success: (response, status, xhr) => {
                let paymentId = event.target.dataset.paymentId;
                let payment = document.getElementById('payment_' + `${paymentId}`)
                payment.remove();
            },
            error: (response, statusText, xhr) => {
                console.error(response, statusText)
            }
        });
    }

    clearFields(){
        this.paymentTypeTargets[0].selectedIndex = 0;
        this.paymentAmountTargets[0].value = '';
        this.noteTargets[0].value = '';
    }

    clearErrors(){
        this.paymentAmountTargets[0].classList.remove('is-invalid');
        document.querySelectorAll('.invalid-feedback').forEach((element) => {
            element.remove();
        });
    }

}