//------------------------------------
//	# Magnifier Controller
//------------------------------------

import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ['backdrop', 'largeImage'];

  zoomIn(event) {
    this.backdropTarget.hidden = false;
    const posX = event.offsetX ? (event.offsetX) : event.pageX - event.currentTarget.offsetLeft;
    const posY = event.offsetY ? (event.offsetY) : event.pageY - event.currentTarget.offsetTop;
    this.backdropTarget.style.backgroundImage = `url(${event.currentTarget.dataset.imageLarge})`;
    this.backdropTarget.style.backgroundPosition = (-posX * 2) + 'px ' + (-posY * 2) + 'px';
    event.currentTarget.style.cursor = "crosshair";
  }

  zoomOut(event) {
    this.backdropTarget.hidden = true;
  }

  openModal(event) {
    document.querySelector('.js-modal-image').src = event.currentTarget.dataset.imageLarge;
    document.querySelector('.js-modal-title').textContent = document.querySelector('.js-product-title').innerHTML;
  }
}