//------------------------------------
//	# Product Variants Controller
//------------------------------------

import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ['fields'];

  add(e) {
    e.preventDefault();
    this.fieldsTarget.insertAdjacentHTML('beforeend', e.target.dataset.fields.replace(/new_field/g, new Date().getTime().toString()));
    document.getElementById(this.fieldsTarget.lastElementChild.id).scrollIntoView();
  }

  remove(e) {
    e.preventDefault();
    document.getElementById(e.target.dataset.productVariantFormId).hidden = true;
    document.getElementById(e.target.dataset.productVariantCheckBoxId).checked = true;
  }

  reload(e){
    let [data, status, xhr] = event.detail;
    document.getElementById(e.currentTarget.dataset.recordId).outerHTML= xhr.response;
  }
}