//------------------------------------
//	# Calculator Controller
//------------------------------------

import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ['price', 'discountPrice'];

  calc(event) {
    if (!this.element.checkValidity() || event.currentTarget.value == '') {
      event.currentTarget.classList.add('is-invalid');
    } else {
      this.priceTarget.textContent = `${this.priceTarget.dataset.price * event.currentTarget.value},00 ₺`;
      if (this.hasDiscountPriceTarget){
        this.discountPriceTarget.textContent = `${this.discountPriceTarget.dataset.discountPrice * event.currentTarget.value},00 ₺`;
      }
    }
  }
}